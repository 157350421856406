import React from 'react';

const Nosotros = () => {
    return (
        <div className='Nosotros'>
            <h1>Quienes Somos?</h1>
            <h2>Ni idea... </h2>
        </div>
    );
}

export default Nosotros;
