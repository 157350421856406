import React from 'react';
import imgTaller from '../../img/taller.jpg'

const Servis = () => {
    return (
        <div className='Servis'>
            <img src={imgTaller} alt="" width="1536px" />
            <h1>En proceso...</h1>
        </div>
    );
}

export default Servis;
